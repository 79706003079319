import styled from 'styled-components'
import { v, fontSize } from 'styles/variables.js'

export const ConfirmBlock = styled.div`
  align-items: center;
  bottom: 0;
  justify-content: center;
  left: 0;
  overflow: hidden;
  padding: 0.4rem;
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  opacity: 1;
  z-index: 999;
`

export const ConfirmBody = styled.div`
  position: relative;
  display: flex;
`

export const ConfirmIcon = styled.div`
  font-size: 30px;
  width: 30px;
  height: 30px;
  line-height: 5px;
  margin-right: 5px;
  color: ${({ theme }) => theme.primary}; ;
`

export const ConfirmOverlay = styled.a`
  background: rgba(4, 4, 4, 0.75);
  bottom: 0;
  cursor: default;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`

export const ConfirmClose = styled.a`
  position: relative;
  top: -72px;
  left: calc((${({ width }) => width} - 96px));
  height: 0px;
  float: right !important;
  text-decoration: none !important;
  cursor: pointer;
  font-size: 1rem;
`

export const ConfirmContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  min-width: 400px;
  /* min-height: 160px; */
  max-width: ${({ maxWidth }) => maxWidth && maxWidth};
  max-height: ${({ maxHeight }) => maxHeight && maxHeight};

  animation: slide-down 0.2s ease 1;
  z-index: 1;
  // box-shadow: 0 0.2rem 0.5rem rgba(48, 55, 66, 0.3);

  background: #f9f9f9;
  border-radius: 30px;
`

export const ConfirmHeader = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.text};
`

export const ConfirmDivider = styled.div`
  border: 1px solid #2e2f33;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 20px 0;
`

export const ConfirmTitle = styled.span`
  font-weight: 700;
  font-size: ${fontSize.xxlText};
  line-height: 32px;
`

export const ConfirmContent = styled.div`
  min-height: 32px;
  padding: 0 35px;
  color: ${({ theme }) => theme.text};
  font-size: ${fontSize.mdText};
`

export const ConfirmFooter = styled.div`
  display: flex;
  margin-left: ${({ margin }) => margin};
  padding: 5px 0px;
`

export const Button = styled.button`
  background: #7b2cbf;
  color: white;
  font-size: 1em;
  margin: 10px;
  padding: 5px 10px;
  border: 2px solid #7b2cbf;
  border-radius: 3px;
  cursor: pointer;
`

export const CenterConfirmFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
`
