import styled from 'styled-components'
import { v } from 'styles/variables.js'

export const Loading = styled.div`
  height: 100%;
  width: 100%;

  align-items: center;
  bottom: 0;
  justify-content: center;
  left: 0;
  overflow: hidden;
  /* padding: 0.4rem; */
  position: fixed;
  right: 0;
  top: 0;
  display: flex;

  color: ${({ theme }) => theme.primary};
`
