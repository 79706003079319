const initialState = false

export default function chatroomLoadReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_CHATROOM_LOAD': {
      return action.payload
    }
    default: {
      return state
    }
  }
}
