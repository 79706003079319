import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  z-index: 100;
  top: 32px;
  right: 90px;
  display: flex;
  flex-direction: row;
`

export const Icon = styled.img`
  width: 30px;
  height: 30px;
  line-height: 40px;
  margin-right: 5px;
  margin-left: 5px;
  /* margin-top: -10px; */
`

export const Span = styled.span`
  display: flex;
  margin-left: 5px;
  span {
    color: ${({ theme }) => theme.primary};
    font-weight: 900;
  }
`

export const ContentHeader = styled.div`
  margin-bottom: 5px;

  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
`

export const Minimize = styled.div`
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  margin-left: auto;
  border-radius: 10px;
  /* // box-shadow: 0 5px 14px rgba(0, 0, 0, 0.2); */

  :hover {
    transform: scale(1.02);
  }

  :active {
    transform: scale(0.98);
  }
`

export const ToggleButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  height: 40px;

  padding: 5px 15px 5px 15px;
  background: whitesmoke;

  box-shadow: ${({ showWarn }) => (showWarn ? '0px 0px 10px rgba(0, 0, 0, 0.2)' : '0px 0px 20px rgba(255, 0, 0, 0.7)')};

  font-weight: bold;
  border-radius: 20px;
  cursor: pointer;

  svg {
    color: ${({ theme, showWarn }) => (showWarn ? theme.primary : theme.danger)};
  }
  margin: 0 10px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 300;
  transform: translateY(10px);
  position: absolute;
  bottom: 20px;
  right: 10px;
  background-color: white;
  width: 400px;
  height: 150px;
  border-radius: 25px;
  box-shadow: 0 5px 14px rgba(0, 0, 0, 0.2);
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 10px;
`

export const InnerContent = styled.div`
  height: 300px;
`

export const WidgetWrapper = styled.div`
  height: 70px;
  background: #ffffff;
  box-shadow: 0px 8px 30px rgba(53, 88, 199, 0.22);
  display: flex;
  align-items: center;
  padding: 10px 5px;
  position: relative;
`

export const UploadDetails = styled.div`
  width: 100%;
`

export const ProgressBar = styled.div`
  width: 100%;
  height: 5px;
  border-radius: 10px;
  background: #e8eef4;
  margin-top: 20px;
  position: relative;

  div {
    width: ${({ percentage }) => `${percentage}%`};
    transition: width 1s;
    height: 100%;
    background: #f17d7d;
  }
`

export const NotUploading = styled.div`
  color: ${({ theme }) => theme.grey};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FileDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const Procress = styled.div`
  font-size: 16px;
  margin-left: auto;
`

export const FileDetails = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
  width: 240px;
  height: 24px;
  display: block;
`

export const Divider = styled.div`
  border: 1px solid #2e2f33;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`
export const Alert = styled.div`
  color: ${({ theme }) => theme.danger};
  font-weight: 900;
`
