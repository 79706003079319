const initialState = {
  show: false,
  title: '',
  content: '',
  func: null,
  footerPosition: 'center',
}

export default function confirmReducer(state = initialState, action) {
  switch (action.type) {
    case 'CONFIRM_SHOW': {
      if (!action.payload.footerPosition) {
        action.payload.footerPosition = 'center'
      }
      return { ...action.payload, show: true }
    }
    case 'CONFIRM_HIDE': {
      return { ...initialState }
    }
    case 'CONFIRM_ONCLICK': {
      state.func()
      return { ...initialState }
    }
    default: {
      return state
    }
  }
}
