const initialState = {
  list: [],
  commentModList: [],
  videoId: '',
  commentModName: '',
  videoUrl: '',
}

export default function commentModReducer(state = initialState, action) {
  switch (action.type) {
    case 'COMMENT_MOD_SET_LIST': {
      state.list = action.payload
      return { ...state }
    }

    case 'COMMENT_MOD_ADD_NEW_COMMENT': {
      state.commentModList.push(action.payload)
      return { ...state }
    }

    case 'COMMENT_MOD_SET_USER': {
      state.commentModList[action.payload.index].userImgId = action.payload.userImgId
      state.commentModList[action.payload.index].userName = action.payload.userName
      return { ...state }
    }

    case 'COMMENT_MOD_SET_COMMENT_TEXT': {
      state.commentModList[action.payload.index].commentText = action.payload.commentText
      return { ...state }
    }

    case 'COMMENT_MOD_SET_COMMENT_TIME': {
      state.commentModList[action.payload.index].commentTime = action.payload.commentTime
      return { ...state }
    }

    case 'COMMENT_MOD_DELETE_SINGLE': {
      state.commentModList.splice(action.payload.index, 1)
      return { ...state }
    }

    case 'COMMENT_MOD_RESET': {
      state.commentModList = []
      state.commentModName = ''
      state.videoId = ''
      state.videoUrl = ''
      state.commentModList = []
      return { ...state }
    }

    case 'COMMENT_MOD_LOADED': {
      state.commentModList = action.payload.commentModData
      state.commentModName = action.payload.commentModName
      state.videoId = action.payload.videoId
      state.videoUrl = action.payload.videoUrl

      return { ...state }
    }

    default:
      return state
  }
}
