const initialState = {
  list: [],
  colorToneId: null,
  sessionInfoList: [],
  // fbPageLink: null,
  // lineId: null,
  templateId: null,
  signupContent: null,
  signupCountdownTitle: null,
  signupSuccessInfo: null,
  signupTitle: null,
  signupName: null,
  signupNotice: null,
  posterInfoList: [],

  posterExtension: null,
  posterImage: null,
  posterImgUrl: null,

  backgroundExtension: null,
  backgroundImage: null,
  backgroundImgUrl: null,

  // sessionExtension: null,
  // sessionImage: null,
  // sessionImgUrl: null,

  metaExtension: null,
  metaImage: null,
  metaImgUrl: null,

  metaTitle: null,
  metaDescription: null,

  orderNoPrefix: null,

  edit: false,
  screenTopText: null,
  //yujuService only
  firmName: '',
  firmImgUrl: '',
}

export default function signUpReducer(state = initialState, action) {
  switch (action.type) {
    case 'SIGNUP_SET_SIGNUP_LIST': {
      state.list = action.payload
      return { ...state }
    }

    case 'SIGNUP_ADD_NEW_SESSION': {
      state.sessionInfoList.push(action.payload)
      return { ...state }
    }

    case 'SIGNUP_DELETE_SESSION': {
      const newSessionInfoList = state.sessionInfoList
      newSessionInfoList.splice(action.payload, 1)
      state.sessionInfoList = [...newSessionInfoList]
      return { ...state }
    }

    case 'SIGNUP_EDIT_SESSION': {
      state.sessionInfoList[action.payload.index] = action.payload

      return { ...state }
    }

    case 'SIGNUP_SUBMIT_FORM': {
      state.colorToneId = action.payload.colorToneId
      // state.fbPageLink = action.payload.fbPageLink
      // state.lineId = action.payload.lineId
      state.signupContent = action.payload.signupContent
      state.signupCountdownTitle = action.payload.signupCountdownTitle
      state.signupName = action.payload.signupName
      state.signupSuccessInfo = action.payload.signupSuccessInfo
      state.signupTitle = action.payload.signupTitle
      state.screenTopText = action.payload.screenTopText
      state.emailLetterTemplate = action.payload.emailLetterTemplate
      state.smsLetterTemplate = action.payload.smsLetterTemplate
      state.signupNotice = action.payload.signupNotice
      state.metaDescription = action.payload.metaDescription
      state.metaTitle = action.payload.metaTitle
      state.orderNoPrefix = action.payload.orderNoPrefix
      return { ...state }
    }

    case 'SIGNUP_SET_COLORTONE': {
      // console.log(action.payload)
      state.colorToneId = action.payload
      return { ...state }
    }

    case 'SIGNUP_SET_BACKGROUND_IMAGE': {
      state.backgroundImage = action.payload.img
      state.backgroundExtension = action.payload.extension
      state.backgroundImgUrl = action.payload.url
      return { ...state }
    }

    case 'SIGNUP_SET_POSTER_IMAGE': {
      state.posterImage = action.payload.img
      state.posterExtension = action.payload.extension
      state.posterImgUrl = action.payload.url
      return { ...state }
    }

    case 'SIGNUP_SET_META_IMAGE': {
      state.metaImage = action.payload.img
      state.metaExtension = action.payload.extension
      state.metaImgUrl = action.payload.url
      return { ...state }
    }

    case 'SIGNUP_DELETE_BACKGROUND_IMAGE': {
      state.backgroundImage = null
      state.backgroundExtension = null
      state.backgroundImgUrl = null
      return { ...state }
    }

    case 'SIGNUP_DELETE_POSTER_IMAGE': {
      state.posterImage = null
      state.posterExtension = null
      state.posterImgUrl = null

      return { ...state }
    }

    case 'SIGNUP_DELETE_META_IMAGE': {
      state.metaExtension = null
      state.metaImage = null
      state.metaImgUrl = null

      return { ...state }
    }

    case 'SIGNUP_RESET': {
      state = initialState
      state.list = []
      state.sessionInfoList = []
      state.posterInfoList = []
      return { ...state }
    }

    case 'SIGNUP_LOAD_ONE': {
      state = { ...action.payload, list: [], edit: true }
      return { ...state }
    }

    default:
      return state
  }
}
