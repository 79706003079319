const initialState = {
  show: false,
  title: '',
  // content: '',
  // func: null,
  // footerPosition: 'center',
}

export default function shoppingCardReducer(state = initialState, action) {
  switch (action.type) {
    case 'SHOPPINGCART_SHOW': {
      return { ...action.payload, show: true }
    }
    case 'SHOPPINGCART_HIDE': {
      return { ...initialState }
    }
    case 'SHOPPINGCART_ONCLICK': {
      state.func()

      return { ...initialState }
    }
    default: {
      return state
    }
  }
}
