import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useBeforeunload } from 'react-beforeunload'
import { roundToTwo } from '.etc/functions.js'
// import { uploadVideoChunk } from 'redux/video/actions'
import { BsArrowUpCircleFill } from 'react-icons/bs'
import * as S from './styles.js'
import * as API from 'apis/video.js'
import { getBase64 } from '.etc/functions.js'
import toast from 'react-hot-toast'

const chunkSize = 1048576 * 5 //its 3MB, increase the number measure in mb

const Uploader = () => {
  const {
    file,
    progress,
    fileName,
    fileSize,
    fileGuid,
    counter,
    offset,
    chunkCount,
    beginingOfTheChunk,
    endOfTheChunk,
    duration,
    thumbnail,
    errors,
  } = useSelector((state) => state.upload)
  const [show, setShow] = useState(false)
  const firmId = useSelector((state) => state.user.firmInfo.firmId)
  const dispatch = useDispatch()

  useBeforeunload((evnet) => (progress > 0 && progress !== 100 ? evnet.preventDefault() : false))

  useEffect(() => {
    if (fileSize > 0) {
      fileUpload(counter)
    }
  }, [file, progress])

  function buttonClicked() {
    setShow(!show)
  }

  const fileUpload = () => {
    dispatch({ type: 'VIDEO_ADD_COUNTER' })
    if (counter <= chunkCount) {
      var chunk = file.slice(beginingOfTheChunk, endOfTheChunk)
      blobToBytes(chunk)
    }
  }

  const blobToBytes = async (chunk) => {
    const base64 = await getBase64(chunk)
    uploadChunk(base64)
  }

  const uploadChunk = async (chunk) => {
    //計算區塊大小(由於base64直接使用chunk.length 取出的數值會大於傳到後端的實際檔案大小 因此需使用file.slice資料進行計算)
    var chunkLength = endOfTheChunk > fileSize ? fileSize - beginingOfTheChunk : endOfTheChunk - beginingOfTheChunk

    try {
      const req = {
        firmId: firmId,
        uuid: fileGuid,
        chunk: chunk,
        offset: offset + chunkLength,
        size: fileSize,
        name: fileName,
        type: '.mp4',
        finished: false,
        duration: duration,
      }

      API.uploadVideo(req)
        .then((res) => {
          // console.log(res)
          if (res.result === 'Success') {
            dispatch({ type: 'VIDEO_ADD_OFFSET', payload: chunkLength })
            dispatch({ type: 'VIDEO_SET_BEGINING_CHUNK', payload: endOfTheChunk })
            dispatch({
              type: 'VIDEO_SET_END_CHUNK',
              payload: endOfTheChunk + chunkSize,
            })
            if (counter == chunkCount) {
              uploadCompleted(chunk, chunkLength)
            } else {
              const countprogress = (counter / chunkCount) * 100
              dispatch({
                type: 'VIDEO_UPLOADING_VIDEO',
                payload: countprogress,
              })
            }
          } else if (res.result === 'error') {
            if (errors >= 10) {
              dispatch({
                type: 'VIDEO_UPLOAD_RESTART',
              })
            } else {
              dispatch({
                type: 'VIDEO_UPLOADING_ADD_ERROR_COUNT',
              })
            }

            uploadChunk(chunk)
          }
        })
        .catch((error) => {
          toast.error(error.error.message)
        })
    } catch (errors) {
      console.log('error', errors)
    }
  }

  const uploadCompleted = async (chunk, add) => {
    const req = {
      firmId: firmId,
      uuid: fileGuid,
      chunk: chunk,
      offset: offset + add,
      size: fileSize,
      name: fileName,
      type: '.mp4',
      finished: true,
      duration: duration,
      thumbnail: thumbnail,
    }
    API.uploadVideo(req).then((res) => {
      if (res.result === 'Success') {
        dispatch({ type: 'VIDEO_UPLOADING_VIDEO', payload: 100 })
      } else {
        if (errors >= 10) {
          dispatch({
            type: 'VIDEO_UPLOAD_RESTART',
          })
        } else {
          dispatch({
            type: 'VIDEO_UPLOADING_ADD_ERROR_COUNT',
          })
          uploadCompleted(chunk, add)
        }
      }
    })
  }

  return (
    <S.Container>
      {file &&
        (show ? (
          <S.Content>
            <S.ContentHeader>
              <S.Title>上傳進度</S.Title>
              <S.Minimize onClick={buttonClicked}>
                <img src="/resources/images/x.png" />
              </S.Minimize>
            </S.ContentHeader>
            <S.Divider />
            {fileName !== '' ? (
              <S.WidgetWrapper>
                <S.UploadDetails>
                  <S.FileDetailsContainer>
                    <S.FileDetails>{fileName}</S.FileDetails>
                    <S.Procress>{roundToTwo(progress)}%</S.Procress>
                  </S.FileDetailsContainer>
                  <S.ProgressBar percentage={progress} className="progress_bar">
                    <div />
                  </S.ProgressBar>
                </S.UploadDetails>
              </S.WidgetWrapper>
            ) : (
              <S.NotUploading>沒有檔案上傳中</S.NotUploading>
            )}
          </S.Content>
        ) : (
          <S.ToogleButton onClick={buttonClicked}>
            <BsArrowUpCircleFill />
            <S.Span>檔案上傳進度</S.Span>
          </S.ToogleButton>
        ))}
    </S.Container>
  )
}

export default Uploader
