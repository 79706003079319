import styled from 'styled-components'
import { v, fontSize } from 'styles/variables.js'

export const ModalBlock = styled.div`
  align-items: ${({ screenHorizon }) => (screenHorizon ? 'center' : 'end')};
  bottom: 0;
  justify-content: center;
  left: 0;
  overflow: hidden;
  /* padding: 0.4rem; */
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  opacity: 1;
  z-index: 500;
`

export const ModalOverlay = styled.a`
  background: ${({ darker }) => (darker ? 'rgba(4, 4, 4, 1)' : 'rgba(4, 4, 4, 0.75)')};
  cursor: default;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
`

export const ModalClose = styled.div`
  position: fixed;
  top: ${({ screenHorizon }) => (screenHorizon ? '30px' : '15px')};
  right: ${({ screenHorizon }) => (screenHorizon ? '36px' : '18px')};
  /* left: calc((${({ width }) => width} - 96px)); */

  height: 0px;
  float: right !important;
  text-decoration: none !important;
  cursor: pointer;
  font-size: 1rem;
`

export const ModalContainer = styled.div`
  /* background: #ffffff; */
  /* border-radius: 0.1rem; */
  display: flex;
  flex-direction: column;
  /* max-height: 75vh; */
  /* max-width: 850px; */
  padding: ${({ screenHorizon }) => (screenHorizon ? '20px' : '10px')};
  /* width: 100%; */
  margin: ${({ screenHorizon }) => (screenHorizon ? '10px' : null)};
  width: ${({ width }) => (width ? width : null)};
  height: ${({ height }) => (height ? height : null)};
  max-width: ${({ maxWidth }) => maxWidth && maxWidth};
  max-height: ${({ maxHeight }) => maxHeight && maxHeight};
  animation: slide-down 0.2s ease 1;
  z-index: 502;
  // box-shadow: 0 0.2rem 0.5rem rgba(48, 55, 66, 0.3);

  background: #f9f9f9;
  border-radius: ${({ screenHorizon }) => (screenHorizon ? '30px' : '30px 30px 0px 0px')};
`

export const ModalBody = styled.div`
  /* overflow-y: auto; */
  /* padding: 30px 10px; */

  /* position: relative;
  display: flex; */

  /* flex-direction: column;
  justify-content: center; */
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : '70vh')};
  overflow-y: auto;
`

export const ModalHeader = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.text};
  /* padding: 20px 5px 10px 5px; */
`

export const ModalDivider = styled.div`
  border: 1px solid #2e2f33;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 20px 0;
`

export const ModalTitle = styled.span`
  font-weight: 700;
  font-size: ${fontSize.mdTitle};
  line-height: 32px;
  text-align: center;
  color: ${({ theme }) => theme.text};
`

export const ModalFooter = styled.div`
  padding: 10px 0px;
  /* text-align: right; */
  display: flex;
  justify-content: end;
`

export const Button = styled.button`
  background: #7b2cbf;
  color: white;
  font-size: 1em;
  margin: 10px;
  padding: 5px 10px;
  border: 2px solid #7b2cbf;
  border-radius: 3px;
  cursor: pointer;
`
