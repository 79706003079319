import styled from 'styled-components'

export const Main = styled('div')`
  /* font-family: sans-serif;
  background: #f0f0f0;
  height: 100vh; */
`

export const DropDownContainer = styled('div')`
  /* width: 100px; */
  margin: 0 auto;
`

export const DropDownHeader = styled('div')`
  font-weight: 500;
  font-size: 20px;
  color: ${({ theme }) => theme.black};
`

export const DropDownListContainer = styled('div')``

export const DropDownList = styled('ul')`
  position: absolute;
  width: 100px;
  padding: 5px;
  border-radius: 10px;
  background: ${({ theme }) => theme.white};
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  color: ${({ theme }) => theme.text};
  font-size: 1.3rem;
  font-weight: 500;
  z-index: 100;
`

export const ListItem = styled('li')`
  list-style: none;
  margin-bottom: 5px;
`

export const MoreBtn = styled.button`
  display: flex;
  border: none;
  border-radius: 50px;
  font-size: 20px;
  padding: 5px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.bg};
  color: ${({ theme }) => theme.primary};

  &:hover {
    opacity: 0.9;
    transform: scale(0.9);
  }
`
