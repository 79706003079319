export const lightTheme = {
  bg: 'rgb(255,255,255)',
  bgAlpha: 'rgba(255,255,255,.3)',
  bg2: 'rgb(245,245,245)',
  bg3: 'rgb(230,230,230)',
  bg4: 'rgba(0, 116, 255, 0.1)',
  bgBlue: 'rgb(218, 232, 247)',
  bgPurple: 'rgb(105, 26, 234,0.2)',
  // bgBlue: 'rgb(226, 247, 243)',
  bgYellow: 'rgb(252, 232, 169)',
  text: 'rgb(46, 47, 51)',
  primary: 'rgb(0, 116, 255)',
  primaryBg: 'rgba(0, 116, 255,0.5)',
  primaryBlue: 'rgb(26, 92, 251)',
  primaryBlueBg: 'rgba(26, 92, 251,0.5)',
  primaryYellow: 'rgb(255, 149, 0)',
  primaryYellowBg: 'rgba(255, 149, 0,0.5)',
  primaryPurple: 'rgb(105, 26, 234)',
  primaryPurpleBg: 'rgba(105, 26, 234,0.2)',
  danger: 'rgb(233, 45, 0)',
  dangerBg: 'rgba(250, 72, 84,0.5)',
  success: 'rgb(0 155 57)',
  successBg: 'rgba(15, 255, 103,0.5)',
  white: 'rgb(255,255,255)',
  black: 'rgb(46, 47, 51)',
  grey: 'rgb(164, 166, 195)',
  purple: 'rgb(105, 26, 234)',
  greyText: 'rgb(124, 124, 124)',
  bgBlueTopText: 'rgba(26, 92, 251,0.2)',
  bgYellowTopText: 'rgba(252, 232, 169, 0.8)',
  bgPurpleTopText: 'rgba(228, 225, 250, 0.8)',
  signupBgY: 'rgba(252, 232, 169,0.4)',
  signupBgB: 'rgba(26, 92, 251,0.2)',
  signupBgP: 'rgba(228, 225, 250, 0.4)',
  notInLiveBadge: 'rgb(190,195,201)',
}
export const darkTheme = {
  bg: 'rgb(15, 15, 15)',
  bgAlpha: 'rgba(0,0,0,.3)',
  bg2: 'rgb(30,30,30)',
  bg3: 'rgb(50,50,50)',
  bg4: 'rgba(218, 213, 249, 0.49)',
  bgBlue: 'rgb(226, 247, 243)',
  bgYellow: 'rgb(252, 232, 169)',
  text: 'rgb(210,210,210)',
  primary: 'rgb(0, 116, 255)',
  primaryBg: 'rgba(26, 92, 251,0.2)',
  primaryBlue: 'rgb(26, 92, 251)',
  primaryBlueBg: 'rgba(26, 92, 251,0.5)',
  primaryYellow: 'rgb(255, 149, 0)',
  primaryYellowBg: 'rgba(255, 149, 0,0.5)',
  danger: 'rgb(250, 72, 84)',
  dangerBg: 'rgba(250, 72, 84,0.5)',
  success: 'rgb(0 155 57)',
  successBg: 'rgba(15, 255, 103,0.5)',
  white: 'rgb(255,255,255)',
  black: 'rgb(46, 47, 51)',
  grey: 'rgb(164, 166, 195)',
}
