import React, { Fragment } from 'react'
import Button from '../Button/index.js'
import PropTypes from 'prop-types'
import { FiAlertCircle } from 'react-icons/fi'
import {
  ConfirmBlock,
  ConfirmBody,
  ConfirmClose,
  ConfirmContainer,
  ConfirmFooter,
  ConfirmOverlay,
  ConfirmTitle,
  ConfirmContent,
  ConfirmIcon,
  CenterConfirmFooter,
} from './styles.js'

const Confirm = ({ title, content, show, hide, maxWidth, maxHeight, isClosable, onClick, footerPosition }) => (
  <Fragment>
    {show && (
      <ConfirmBlock>
        <ConfirmOverlay onClick={(e) => (isClosable ? hide() : e.preventDefault())}></ConfirmOverlay>
        <ConfirmContainer maxWidth={maxWidth} maxHeight={maxHeight}>
          <ConfirmBody>
            <ConfirmIcon>
              <FiAlertCircle />
            </ConfirmIcon>
            <ConfirmTitle>{title}</ConfirmTitle>
          </ConfirmBody>
          <ConfirmContent>{content}</ConfirmContent>
          {footerPosition === 'center' ? (
            <CenterConfirmFooter>
              <Button secondary onClick={hide}>
                取消
              </Button>
              <div style={{ marginRight: '10px' }} />
              <Button primary onClick={onClick}>
                確定
              </Button>
            </CenterConfirmFooter>
          ) : footerPosition !== 'null' ? (
            <ConfirmFooter>
              <div style={{ marginRight: 10 }}>
                <Button secondary onClick={hide}>
                  取消
                </Button>
              </div>
              <Button primary onClick={onClick}>
                確定
              </Button>
            </ConfirmFooter>
          ) : null}
        </ConfirmContainer>
      </ConfirmBlock>
    )}
  </Fragment>
)

Confirm.propTypes = {
  title: PropTypes.string,
  content: PropTypes.node,
  maxWidth: PropTypes.string,
  maxHeight: PropTypes.string,
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  isClosable: PropTypes.bool,
  footerPosition: PropTypes.string,
}

Confirm.defaultProps = {
  isClosable: true,
}

export default Confirm
