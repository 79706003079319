const initialState = {
  list: [],
  letterTemplateSubject: '',
  letterTemplateContent: '',
  letterTemplateType: 0,
  letterTemplateName: '',
}

export default function mailTemplateReducer(state = initialState, action) {
  switch (action.type) {
    case 'MAIL_TEMPLATE_LOAD_LIST': {
      state.list = action.payload
      return { ...state }
    }
    case 'MAIL_TEMPLATE_RESET': {
      return initialState
    }
    case 'MAIL_TEMPLATE_LOAD_TEMPLATE': {
      return { list: state.list, ...action.payload }
    }
    case 'MAIL_TEMPLATE_CHANGE_TYPE': {
      state.letterTemplateType = action.payload
      state.letterTemplateContent = ''
      return { ...state }
    }

    case 'MAIL_TEMPLATE_SET_DATA': {
      state.letterTemplateSubject = action.payload.letterTemplateSubject
      state.letterTemplateContent = action.payload.letterTemplateContent
      state.letterTemplateName = action.payload.letterTemplateName
      return { ...state }
    }
    default: {
      return state
    }
  }
}
