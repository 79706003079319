import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { btnReset, v, fontSize } from '../../styles/variables'

export const LogoBig = styled.div`
  /* width: 52px; */
  height: 50px;
  img {
    max-width: 100%;
    /* height: auto; */
  }
  cursor: pointer;

  display: flex;
  margin-bottom: ${v.lgSpacing};
`

export const LogoSmall = styled.div`
  /* width: 52px; */
  /* height: 50px; */
  img {
    max-width: 100%;
    /* height: auto; */
  }
  cursor: pointer;

  display: flex;
  margin-bottom: ${v.lgSpacing};
`

export const LogoLabel = styled.div`
  display: block;
  font-weight: 900;
  flex: 1;
  margin-left: ${v.smSpacing};
`

export const UserLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  flex: 1;
  margin-left: 16px;
  color: ${({ theme }) => theme.black};
  span {
    /* line-height: 14px; */
    display: flex;
  }
  svg {
    font-size: 26px;
    margin-right: 10px;
  }
`

export const Sidebar = styled.div`
  width: ${({ isOpen }) => (!isOpen ? `100px` : v.sidebarWidth)};
  background: ${({ theme }) => theme.bg};
  height: 100vh;
  padding: ${v.lgSpacing};
  position: relative;
`

export const SidebarButton = styled.button`
  ${btnReset};
  position: absolute;
  top: calc(${v.xxlSpacing} + 36px);
  right: ${({ isOpen }) => (isOpen ? `-16px` : `-40px`)};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${({ theme }) => theme.bg};
  /* box-shadow: 0 0 4px ${({ theme }) => theme.bg3}, 0 0 3px ${({ theme }) => theme.bg}; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: ${({ isOpen }) => (isOpen ? `rotate(180deg)` : `initial`)};
`

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.bg3};
  margin: ${v.lgSpacing} 0;
`
export const LinkScroll = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - (209px));
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const LinkContainer = styled.div`
  background: ${({ theme, isActive }) => (!isActive ? `transparent` : theme.bg3)};
  border-radius: ${v.borderRadius};
  margin: 8px 0;

  :hover {
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.bg3};
  }
`

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  font-size: 16px;
  margin: auto;
  padding: calc(${v.smSpacing} - 2px) 0;
`

export const LinkIcon = styled.div`
  padding: ${v.smSpacing} 20px;
  display: flex;

  svg {
    font-size: 20px;
  }
`
export const LinkLabel = styled.div`
  display: block;
  flex: 1;
  /* margin-left: ${v.smSpacing}; */
  color: ${({ theme }) => theme.text};
`

export const LinkNotification = styled.div`
  font-size: 14px;
  padding: calc(${v.smSpacing} / 2) ${v.smSpacing};
  border-radius: calc(${v.borderRadius} / 2);
  background: ${({ theme }) => theme.primary};
  color: white;

  margin-right: ${v.mdSpacing};
`

export const Theme = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
`

export const ThemeLabel = styled.span`
  display: block;
  flex: 1;
`

export const ThemeToggler = styled.button`
  ${btnReset};
  margin: 0 auto;
  cursor: pointer;
  width: 36px;
  height: 20px;
  border-radius: 10px;
  background: ${({ theme, isActive }) => (!isActive ? theme.bg3 : theme.primary)};

  position: relative;
`

export const ToggleThumb = styled.div`
  height: 18px;
  width: 18px;
  position: absolute;
  top: 1px;
  bottom: 1px;
  transition: 0.2s ease right;
  right: calc(100% - 18px - 1px);
  border-radius: 50%;
  background: ${({ theme }) => theme.bg};
`

export const MoreItem = styled.button`
  display: flex;
  border: none;
  width: 100%;
  font-size: ${fontSize.mdText};
  padding: 5px 10px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.bg};
  color: ${({ theme }) => theme.text};

  &:hover {
    opacity: 0.9;
    transform: scale(0.98);
  }
`

export const Button = styled.div`
  background-color: ${({ theme }) => theme.danger};
  color: ${({ theme }) => theme.white};
  font-size: 12px;
  padding: 0 5px;
  margin: 0px;
  border-radius: 20px;

  svg {
    width: 20px;
    height: 20px;
    margin: 0 4px;
  }
`

export const Avatar = styled.div`
  display: flex;
  margin-right: 10px;
  /* cursor: pointer; */
  color: ${({ theme }) => theme.white};
  img {
    width: 30px;
    height: 30px;
    border-radius: 50px;
  }
`
