import styled from 'styled-components'
import { v } from 'styles/variables.js'

export const Card = styled.div`
  /* width: 52px; */

  flex-direction: column;
  align-items: center;
  padding: 2rem 2.25rem;
  background: ${({ theme }) => theme.bg};
  border: 1px solid ${({ theme }) => theme.bg3};
  box-sizing: border-box;
  /* margin-bottom: ${v.lgSpacing}; */
  border-radius: 40px;
  margin: 10px 0 10px 0;
`
