import React from 'react'
import * as S from './styles'
import PropTypes from 'prop-types'

function Card({ children }) {
  return <S.Card>{children}</S.Card>
}

export default Card

Card.propTypes = {
  children: PropTypes.node,
}
