const initialState = null

export default function streamingReducer(state = initialState, action) {
  switch (action.type) {
    case 'STREAMING_RESET': {
      return null
    }
    case 'STREAMING_LOAD_DATA': {
      return { ...action.payload }
    }
    default: {
      return state
    }
  }
}
