import en from './locales/en.json'
import tw from './locales/zh-TW.json'

const resources = {
  en: {
    translation: en,
  },
  'zh-TW': {
    translation: tw,
  },
}

export default resources
