import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducer'
import settings from './settings/reducer'
import virtualUser from './virtual-user/reducer'
import video from './video/reducer'
import upload from './video/uploadReducer'
import product from './product/reducer'
import signUp from './sign-up/reducer'
import liveStream from './live-stream/reducer'
import commentMod from './commentMod/reducer'
import confirm from './confirm/reducer'
import shoppingCart from './shoppingCart/reducer'
import dashboard from './dashboard/redcuer'
import streaming from './streaming/reducer'
import smsEmail from './sms-email/reducer'
import chat from './chat/reducer'
import blocklist from './blocklist/reducer'
import chatUser from './chat-user/reducer'
import firmBlocklist from './firm-blocklist/reducer'
import mailTemplate from './mail-template/reducer'
import chatroomLoadReducer from './chat/loadReducer'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    settings,
    virtualUser,
    video,
    upload,
    product,
    signUp,
    liveStream,
    commentMod,
    confirm,
    shoppingCart,
    dashboard,
    streaming,
    smsEmail,
    chat,
    blocklist,
    chatUser,
    firmBlocklist,
    mailTemplate,
    chatroomLoad: chatroomLoadReducer,
  })
