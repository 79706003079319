import { css } from 'styled-components'

export const v = {
  sidebarWidth: `300px`,
  flexWidth: `1200px`,
  smSpacing: `8px`,
  mdSpacing: `16px`,
  lgSpacing: `24px`,
  xlSpacing: `32px`,
  xxlSpacing: `48px`,
  borderRadius: `10px`,
}

export const fontSize = {
  smTitle: `22px`,
  mdTitle: `24px`,
  lgTitle: `30px`,
  smText: `12px`,
  mdText: `14px`,
  lgText: `16px`,
  xlText: `18px`,
  xxlText: `20px`,
}

export const btnReset = css`
  font-family: inherit;
  border: none;
  background: none;
  letter-spacing: inherit;
  color: inherit;
  font-size: inherit;
  text-align: inherit;
  padding: 0;
`
