const initialState = {
  users: [],
  images: [],
  currentUser: null,
}

export default function virtualUserReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_VIRTUAL_USER_LIST': {
      state.users = action.payload
      return { ...state }
    }

    case 'SET_VIRTUAL_USER_IMAGE_LIST': {
      state.images = action.payload
      return { ...state }
    }

    case 'SET_CURRENT_VIRTUAL_USER': {
      state.currentUser = action.payload
      return { ...state }
    }

    case 'SET_CURRENT_VIRTUAL_USER_NAME': {
      state.currentUser.name = action.payload
      return { ...state }
    }

    case 'SET_CURRENT_VIRTUAL_USER_IMG_ID': {
      state.currentUser.userImgId = action.payload
      return { ...state }
    }

    default:
      return state
  }
}
