import { teddyApi } from './base.ts'

export const loadFirmLiveHours = async (firmId, liveHoursBegin, liveHoursEnd) => {
  const { data } = await teddyApi.dashboard.liveLoadFirmLiveHours({
    firmId: firmId,
    liveHoursBegin: liveHoursBegin,
    liveHoursEnd: liveHoursEnd,
  })
  return data
}

export const loadLiveDashboard = async (liveId, firmId, realDataStatus) => {
  const { data } = await teddyApi.dashboard.liveLoadLiveDashboard({
    liveId: liveId,
    firmId: firmId,
    realDataStatus: realDataStatus,
  })
  return data
}

export const loadAnnouncement = async () => {
  const { data } = await teddyApi.loadAnnouncementList.cutrackLoadAnnouncementList()
  return data
}
