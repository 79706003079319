import React from 'react'
import * as S from './styles'
import PropTypes from 'prop-types'

function Button({
  children,
  width,
  height,
  type,
  size,
  primary,
  secondary,
  danger,
  dangerSubmit,
  disabled,
  onClick,
  formTheme,
  className,
}) {
  return (
    <>
      {/* 主要按鈕 */}
      {primary === true && !disabled && (
        <S.PriButton className={className} formTheme={formTheme} type={type} size={size} width={width} height={height} onClick={onClick}>
          {children}
        </S.PriButton>
      )}
      {/* 次要按鈕 */}
      {secondary === true && !disabled && (
        <S.SecButton className={className} type={type} size={size} width={width} height={height} onClick={onClick}>
          {children}
        </S.SecButton>
      )}
      {/* 危險按鈕 */}
      {danger === true && !disabled && (
        <S.DangerButton className={className} type={type} size={size} width={width} height={height} onClick={onClick}>
          {children}
        </S.DangerButton>
      )}
      {/* 阻擋按鈕 */}
      {disabled === true && !dangerSubmit && (
        <S.DisabledButton
          className={className}
          formTheme={formTheme}
          size={size}
          disabled={disabled}
          width={width}
          height={height}
          onClick={onClick}
        >
          {children}
        </S.DisabledButton>
      )}
      {/* 未選擇危險傳送按鈕 */}
      {dangerSubmit && !disabled && (
        <S.DangerSubmitButton className={className} type={type} size={size} width={width} height={height} onClick={onClick}>
          {children}
        </S.DangerSubmitButton>
      )}
      {/* 選擇危險傳送按鈕 */}
      {dangerSubmit && disabled && (
        <S.DisabledDangerSubmitButton className={className} size={size} disabled={disabled} width={width} height={height} onClick={onClick}>
          {children}
        </S.DisabledDangerSubmitButton>
      )}
    </>
  )
}

Button.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.string,
  size: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  danger: PropTypes.bool,
  dangerSubmit: PropTypes.bool,
  disabled: PropTypes.bool,
  formTheme: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

Button.defaultProps = {
  type: 'button',
  size: 'normal',
}

export default Button
