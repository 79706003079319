import React, { useEffect, useState } from 'react'
import Modal from 'components/Elements/Modal/index.js'
import Tag from 'components/Elements/Tag'
import Card from 'components/Elements/Card'
import { FaBell } from 'react-icons/fa'
import * as S from './styles.js'
import * as API from 'apis/dashboard'
import useSWR from 'swr'
import { ANNOUNCEMENTS } from '.etc/announcements'
import moment from 'moment'

const Alert = () => {
  const [showModal, setShowModal] = useState(false)
  const [count, setCount] = useState(0)
  const { data: data = { announcementList: [] } } = useSWR('/api/alerts', () => API.loadAnnouncement())

  useEffect(() => {
    const total = data.announcementList.reduce((state, current) => {
      if (moment(current.expireDate) > moment()) {
        console.log('current: ', current.expireDate)
        return (state += 1)
      }
      return state
    }, 0)
    setCount(total)
  }, [data.announcementList])

  return (
    <S.Container>
      <S.ToggleButton showWarn={true} onClick={() => setShowModal(true)}>
        <FaBell />
      </S.ToggleButton>
      {count > 0 && (
        <S.Alert>
          <Tag color="red">{count}</Tag>
        </S.Alert>
      )}
      {/* 系統公告彈跳Modal */}
      <Modal
        footer={null}
        title="系統公告"
        active={showModal}
        hideModal={() => {
          setShowModal(false)
        }}
        width="50vw"
        maxWidth="700px"
        maxHeight="70vh"
        closeIcon={true}
        header={true}
        isClosable={true}
      >
        {data.announcementList.map((item, index) => (
          <Card key={item.announcementId}>
            <S.CardToolbar>
              <S.Tags>
                {item.type.map((text, index) => (
                  <Tag color={ANNOUNCEMENTS[text]?.color || 'gray'} key={index}>
                    {ANNOUNCEMENTS[text]?.text}
                  </Tag>
                ))}
              </S.Tags>
              <S.Times>{item.createDate} 發布</S.Times>
            </S.CardToolbar>
            <S.CardTitle>{item.name}</S.CardTitle>
            <S.CardContent dangerouslySetInnerHTML={{ __html: item.content }} />
          </Card>
        ))}
      </Modal>
    </S.Container>
  )
}

export default Alert
