import React from 'react'
import * as S from './styles'
import Sidebar from 'components/Sidebar'
import { useSelector } from 'react-redux'
import Uploader from 'components/Uploader'
import PropTypes from 'prop-types'
import Timer from 'components/Elements/Timer'
import Alert from 'components/Elements/Alert'
import SmsAlert from '../SmsAlert'

const Layout = ({ children, path }) => {
  const page = path.location.pathname
  const isMenuCollapsed = useSelector((state) => state.settings.isMenuCollapsed)
  const list = [
    '/sign-up',
    '/stream/',
    '/proto',
    '/front-form',
    '/register',
    '/forget',
    '/reset',
    '/login',
    '/client-404',
    '/partner/',
    '/f/',
    '/s/',
  ]

  const SidebarRender = () => {
    const match = list.reduce((state, current) => {
      if (page.includes(current)) {
        return true
      }
      return state
    }, false)
    return match ? (
      children
    ) : (
      <>
        <Sidebar />
        <S.Main isMenuCollapsed={isMenuCollapsed}>
          <S.Flex isManageLiveStream={page.includes('manage-orders')}>{children}</S.Flex>
          <Uploader />
          <Timer />
          <Alert />
        </S.Main>
      </>
    )
  }

  return <S.Layout>{SidebarRender()}</S.Layout>
}

Layout.propTypes = {
  children: PropTypes.node,
  path: PropTypes.object,
}

export default Layout
