import styled from 'styled-components'
import { v, fontSize } from 'styles/variables.js'

export const PriButton = styled.button`
  /* display: flex;
  justify-content: center;
  align-items: center; */
  border-radius: calc(${v.borderRadius} + 5px);
  border: none;
  cursor: pointer;
  font-size: ${({ size }) => (size === 'small' ? `${fontSize.smText}` : `${fontSize.mdText}`)};
  font-weight: 700;
  padding: ${({ size }) => (size === 'small' ? '3px 5px' : '5px 10px')};
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : null)};
  background-color: ${({ theme, formTheme }) =>
    formTheme === '2' ? theme.primaryYellow : formTheme === '3' ? theme.primaryPurple : theme.primary};
  color: ${({ theme }) => theme.white};
  border: 1px solid null;
  /* box-shadow: -11.3201px 2.82242px 23.3333px 
  ${({ formTheme }) =>
    formTheme === '2' ? 'rgba(255, 199, 29, 0.4)' : formTheme === '3' ? 'rgba(226, 247, 243, 0.4)' : 'rgba(218, 213, 249, 0.4)'},
  inset 0px 0px 17px 1px
      ${({ theme, formTheme }) => (formTheme === '2' ? theme.bgYellow : formTheme === '3' ? theme.bgBlue : theme.bg4)},
    inset -2.54074px 0.633477px 2.61852px ${({ theme, formTheme }) =>
    formTheme === '2' ? theme.primaryYellow : formTheme === '3' ? theme.primaryBlue : theme.primary};
*/
  &:hover {
    opacity: 0.9;
    transition: opacity 300ms, transform 300ms;
    transform: scale(1.02);
  }
`

export const SecButton = styled.button`
  border-radius: calc(${v.borderRadius} + 5px);
  border: none;
  cursor: pointer;
  font-size: ${({ size }) => (size === 'small' ? `${fontSize.smText}` : `${fontSize.mdText}`)};
  font-weight: 700;
  padding: ${({ size }) => (size === 'small' ? '3px 5px' : '5px 10px')};
  /* padding: 5px 10px; */
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : null)};
  background-color: ${({ theme }) => theme.bg4};
  color: ${({ theme }) => theme.primary};

  border: 1px solid ${({ theme }) => theme.primary};

  &:hover {
    opacity: 0.9;
    transition: opacity 300ms, transform 300ms;
    transform: scale(1.02);
  }
`

export const DangerButton = styled.button`
  border-radius: calc(${v.borderRadius} + 5px);
  border: none;
  cursor: pointer;
  font-size: ${({ size }) => (size === 'small' ? `${fontSize.smText}` : `${fontSize.mdText}`)};
  font-weight: 700;
  padding: 5px 10px;
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : null)};
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.danger};

  &:hover {
    opacity: 0.9;
    transition: opacity 300ms, transform 300ms;
    transform: scale(1.02);
  }
`

export const DisabledButton = styled.button`
  border-radius: calc(${v.borderRadius} + 5px);
  border: none;
  cursor: pointer;
  font-size: ${({ size }) => (size === 'small' ? `${fontSize.smText}` : `${fontSize.mdText}`)};
  font-weight: 700;
  padding: ${({ size }) => (size === 'small' ? '3px 5px' : '5px 10px')};
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : null)};
  color: ${({ theme }) => theme.white};

  border: 1px solid null;
  /* box-shadow: -11.3201px 2.82242px 23.3333px rgba(97, 0, 255, 0.1035),
    inset 0px 0px 17px 1px #d394fc,
    inset -2.54074px 0.633477px 2.61852px #721cfd;*/

  &:hover {
    opacity: 0.9;
    transition: opacity 300ms, transform 300ms;
    transform: scale(1.02);
  }

  &:disabled {
    /* box-shadow: none; */
    border: 1px solid ${({ theme }) => theme.grey};
    background: ${({ theme, formTheme }) =>
      formTheme === '2' ? theme.bgYellow : formTheme === '3' ? theme.bgPurple : formTheme === '1' ? theme.bg4 : theme.white};

    color: ${({ theme }) => theme.grey};
  }
`

export const DangerSubmitButton = styled.button`
  border-radius: 20px;
  border: none;
  cursor: pointer;
  font-size: ${({ size }) => (size === 'small' ? `${fontSize.smText}` : `${fontSize.mdText}`)};
  font-weight: 700;
  padding: ${({ size }) => (size === 'small' ? '3px 5px' : '5px 10px')};
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : null)};
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.danger};

  &:hover {
    opacity: 0.9;
    transition: opacity 300ms, transform 300ms;
    transform: scale(1.02);
  }
`

export const DisabledDangerSubmitButton = styled.button`
  border-radius: 20px;
  border: none;
  cursor: pointer;
  font-size: ${({ size }) => (size === 'small' ? `${fontSize.smText}` : `${fontSize.mdText}`)};
  font-weight: 700;
  padding: ${({ size }) => (size === 'small' ? '3px 5px' : '5px 10px')};
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : null)};
  color: ${({ theme }) => theme.white};

  border: 1px solid null;

  &:hover {
    opacity: 0.9;
    transition: opacity 300ms, transform 300ms;
    transform: scale(1.02);
  }

  &:disabled {
    /* box-shadow: none; */
    border: none;
    /* border: 1px solid ${({ theme }) => theme.grey}; */
    background: ${({ theme }) => theme.dangerBg};

    color: ${({ theme }) => theme.white};
  }
`
