const initialState = {
  list: [],
  log: [],
  liveId: '',
  emailSubject: '',
  emailContent: '',
  smsContent: '',
  users: [],
}

export default function smsEmailReducer(state = initialState, action) {
  switch (action.type) {
    case 'SMS_EMAIL_LOAD_LIST': {
      state.list = action.payload
      return { ...state }
    }
    case 'SMS_EMAIL_LOAD_LOG': {
      state.log = action.payload
      return { ...state }
    }
    case 'SMS_EMAIL_SET_LIVEID': {
      state.liveId = action.payload
      return { ...state }
    }
    case 'SMS_EMAIL_SET_DATA': {
      state.emailSubject = action.payload.emailSubject
      state.emailContent = action.payload.emailContent
      state.smsContent = action.payload.smsContent
      return { ...state }
    }
    case 'SMS_EMAIL_SET_USERS': {
      state.users = action.payload
      return { ...state }
    }

    default: {
      return state
    }
  }
}
