import { fromUnixTime } from 'date-fns'
import React from 'react'
import * as S from './styles'
import PropTypes from 'prop-types'

function Tag({ children, color }) {
  return <S.Tag color={color}>{children}</S.Tag>
}

Tag.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
}

Tag.defaultProps = {
  children: '標籤',
}

export default Tag
