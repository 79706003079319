import styled from 'styled-components'
import { v } from 'styles/variables.js'

export const Layout = styled.div`
  display: flex;
`

export const Main = styled.main`
  width: ${({ isMenuCollapsed }) => (isMenuCollapsed ? `calc(100vw - 300px)` : `calc(100vw - 107.469px)`)};
  height: 100vh;
  overflow-y: auto;
`

export const Flex = styled.div`
  padding: ${v.xlSpacing};
  max-width: ${({ isManageLiveStream }) => (isManageLiveStream ? null : `${v.flexWidth}`)};

  /* min-width: 100%; */
  display: flex;
  align-items: center;
  margin: 0 auto;
  & > div,
  & > ui {
    flex: 1;
  }
`
